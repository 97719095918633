<template>
    <div class="content" v-loading.fullscreen.lock="loading">
      <base-header class="pb-6">
        <div class="row align-items-center py-2">
          <div class="col-lg-6 col-7">
            <!-- Could place a headline or breadcrumb here -->
          </div>
        </div>
      </base-header>
  
      <div class="container-fluid mt--6">
        <div>
          <div class="dash-header mb-4">
            <h2 class="text-default m-0 d-flex">
              User Activity History
              <img src="/signup-img/fire.svg" alt="" />
            </h2>
          </div>
          <card class="no-border-card" footer-classes="pt-1">
            <template slot="header">
              <div class="row align-items-center">
                <div class="col-md-6 col-lg-6">
                  <!-- <h2 class="mb-0">User Activity History</h2> -->
                </div>
  
                <div class="col-lg-6 col-md-6 col-12 text-right">
                  <base-button
                    name="Clear Filters"
                    type="primary"
                    outline="true"
                    @click="resetFilters"
                  >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                    Clear Filters
                  </base-button>
                  <base-button
                    type="primary"
                    name="Export Excel"
                    @click="exportExcel"
                  >
                    Excel Download
                  </base-button>
                </div>
              </div>
            </template>
  
            <div>
              <!-- FILTERS (Row 1) -->
              <div
                class="row d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <!-- Search box -->
                <div class="col-md-3 mb-2">
                  <base-input
                    label="Search:"
                    name="Search"
                    class="search-style"
                    v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Search Keyword"
                  >
                  </base-input>
                </div>
  
                <!-- Company/Location Filter -->
                <div class="col-md-3">
                  <base-input label="Company:">
                    <el-select
                      filterable
                      name="Company Filter"
                      class="select-primary select-style w-100"
                      @change="fetchData"
                      v-model="filters.location_id"
                      placeholder="Filter by Location"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in locations"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
  
                <!-- Per-Page Selector -->
                <div class="col-md-3">
                  <base-input label="Showing:">
                    <el-select
                      class="select-primary pagination-select select-style w-100"
                      v-model="perPage"
                      @change="changePage(1)"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </base-input>
                </div>
  
                <!-- User Status Filter -->
                <div class="col-md-2 mb-2">
                  <label class="form-control-label">User Status:</label>
                  <el-select
                    class="select-primary select-style w-100"
                    v-model="filters.employeStatus"
                    @change="changePage(1)"
                    placeholder="Filter by status"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in status"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
  
                <!-- Date Range Filter -->
                <div class="col-md-3 calender-style">
                  <base-input label="Date Range:">
                    <el-date-picker
                      v-model="filters.report_date"
                      @change="fetchData"
                      type="daterange"
                      unlink-panels
                      value-format="MM/DD/YYYY"
                      range-separator="-"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      style="width: 100%"
                    />
                  </base-input>
                </div>
              </div>
  
              <!-- MAIN TABLE -->
              <div class="user-eltable table-style-fancy border overflow-hidden">
                <el-table
                  role="table"
                  :data="tableData"
                  stripe
                  highlight-current-row
                  lazy
                  row-key="id"
                  id="tableOne"
                  header-row-class-name="thead-light"
                  class="loginReportGrid table-striped"
                >
                  <!-- Activity Type Column -->
                  <el-table-column min-width="120px" prop="activity_type_label">
                    <template slot="header">
                      <span @click="sortByColumn(0)">
                        Activity
                        <i
                          v-if="sortedColumn === 0 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      {{ props.row.activity_type_label }}
                    </template>
                  </el-table-column>
  
                  <!-- Performed By Column -->
                  <el-table-column min-width="140px" prop="performed_by_name">
                    <template slot="header">
                      <span @click="sortByColumn(1)">
                        Performed By
                        <i
                          v-if="sortedColumn === 1 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      {{ props.row.performed_by_name }}
                    </template>
                  </el-table-column>
  
                  <!-- Performed On Column -->
                  <el-table-column min-width="140px" prop="performed_on_name">
                    <template slot="header">
                      <span @click="sortByColumn(2)">
                        Performed On
                        <i
                          v-if="sortedColumn === 2 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      <span v-if="props.row.performed_on_name">
                        {{ props.row.performed_on_name }}
                      </span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
  
                  <!-- Description Column -->
                  <el-table-column min-width="180px" prop="description_text">
                    <template slot="header">
                      <span @click="sortByColumn(3)">
                        Description
                        <i
                          v-if="sortedColumn === 3 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      {{ props.row.description_text }}
                    </template>
                  </el-table-column>
  
                  <!-- Date/Time Column -->
                  <el-table-column min-width="130px" prop="created_at">
                    <template slot="header">
                      <span @click="sortByColumn(4)">
                        Activity Date
                        <i
                          v-if="sortedColumn === 4 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      {{ props.row.created_at }}
                    </template>
                  </el-table-column>
  
                  <!-- Location Column (optional) -->
                  <el-table-column min-width="100px">
                    <template slot="header">
                      <span @click="sortByColumn(5)">
                        Location
                        <i
                          v-if="sortedColumn === 5 && order === 'asc'"
                          class="fas fa-arrow-up text-blue linkColor"
                        />
                        <i
                          v-else
                          class="fas fa-arrow-down text-blue linkColor"
                        />
                      </span>
                    </template>
                    <template slot-scope="props">
                      <span v-if="props.row.location">
                        {{ props.row.location }}
                      </span>
                      <span v-else> -</span>
                    </template>
                  </el-table-column>
  
                  <!-- Action Column (eye icon to show more details) -->
                  <el-table-column min-width="100px">
                    <template slot="header">
                      Action
                    </template>
                    <template slot-scope="props">
                      <span @click.prevent="showDetailsModal(props.row.id)">
                        <img
                          class="ico-eye"
                          src="/img/icons/common/eye-icon.svg"
                          alt=""
                          width="16px"
                          height="16px"
                        />
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
  
            <!-- PAGINATION FOOTER -->
            <div slot="footer" class="d-flex justify-content-end">
              <nav v-if="pagination && tableData.length > 0">
                <div class="row">
                  <div class="col-md-12">
                    <ul
                      class="
                        pagination
                        custompagination
                        justify-content-end
                        align-items-center
                      "
                    >
                      <p class="p-0 m-0 mr-2">
                        Showing {{ tableData.length }} of {{ totalData }} entries
                      </p>
                      <li
                        class="page-item"
                        :class="{ disabled: currentPage === 1 }"
                      >
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="changePage(currentPage - 1)"
                          ><i class="fa fa-caret-left"></i
                        ></a>
                      </li>
                      <li
                        v-for="(page, index) in pagesNumber"
                        class="page-item"
                        :class="{ active: page === currentPage }"
                        :key="index"
                      >
                        <a
                          href="javascript:void(0)"
                          @click.prevent="changePage(page)"
                          class="page-link"
                          >{{ page }}</a
                        >
                      </li>
                      <li
                        class="page-item"
                        :class="{ disabled: currentPage === last_page }"
                      >
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="changePage(currentPage + 1)"
                          ><i class="fa fa-caret-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </card>
        </div>
      </div>
  
      <!-- MODAL: Detailed Info -->
      <modal :show.sync="showDetails" class="user-modal">
        <span slot="header" class="title title-up text-primary assign-coures" style="width: 100%">
          Activity Details
        </span>
        <div class="col-md-12">
          <!-- Example: Show full user history in a table, or a single record’s data -->
          <el-table
            :data="tableDataHistory"
            stripe
            highlight-current-row
            lazy
            row-key="id"
            id="tableModal"
            header-row-class-name="thead-light"
            class="loginReportGrid table-striped"
            style="max-height: 600px; overflow-y: auto;"
          >
            <!-- Could use the same columns or just a subset -->
            <el-table-column min-width="140px" prop="activity_type_label" label="Activity" />
            <el-table-column min-width="140px" prop="performed_by_name" label="Performed By" />
            <el-table-column min-width="140px" prop="performed_on_name" label="Performed On" />
            <el-table-column min-width="180px" prop="description_text" label="Description" />
            <el-table-column min-width="130px" prop="created_at" label="Activity Date" />
          </el-table>
        </div>
      </modal>
    </div>
  </template>
  
  <script>
  import {
    DatePicker,
    Table,
    TableColumn,
    Select,
    Option
  } from "element-ui";
  
  import serverSidePaginationMixin from "../Tables/PaginatedTables/serverSidePaginationMixin";
  import XLSX from "xlsx";
  import moment from "moment";
  
  let timeout = null;
  
  export default {
    name: "UserActivityHistory",
    mixins: [serverSidePaginationMixin], // Or adapt to your own pagination
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        // For the modal
        showDetails: false,
        tableDataHistory: [],
  
        // Loading overlay
        loading: false,
  
        // Filter options
        locations: [
          {
            label: "All",
            value: "",
          },
        ],
        searchQuery: "",
        filters: {
          location_id: "",
          employeStatus: "2", // Maybe "2" means "All" in your system
          report_date: [
            moment().subtract(7, "days").format("MM/DD/YYYY"),
            moment().format("MM/DD/YYYY"),
          ], // default last 7 days
        },
  
        // Main table data
        tableData: [],
        totalData: 0,
  
        // Example status array
        status: [
          {
            label: "Active",
            value: "1",
          },
          {
            label: "Inactive",
            value: "0",
          },
          {
            label: "All",
            value: "2",
          },
        ],
  
        // If needed for role or other usage
        editor: "",
  
        // For Excel export
        items: [],
      };
    },
    watch: {
      // Debounce the search to avoid sending too many requests
      searchQuery() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.fetchData();
        }, 300);
      },
    },
    created() {
      // Example: identify user role (if needed)
      if (localStorage.getItem("hot-user") === "super-admin") {
        this.editor = "super-admin";
      }
      if (localStorage.getItem("hot-user") === "sub-admin") {
        this.editor = "sub-admin";
      }
  
      // Example: fetch company/locations
      this.$http
        .post("location/all_company_location", {
          role: this.editor,
        })
        .then((resp) => {
          for (let loc of resp.data) {
            this.locations.push({
              label: loc.name,
              value: loc.id,
            });
          }
        });
  
      // Fetch initial data
      this.fetchData();
    },
    methods: {
      // Show detail modal for a specific record
      showDetailsModal(activityId) {
        this.loading = true;
        this.$http
          .post("user/activity-details", {
            id: activityId,
          })
          .then((resp) => {
            // 'resp.data' could be:
            // {
            //    history: [ ... array of detailed records ... ]
            // }
            this.tableDataHistory = resp.data.history || [];
            this.showDetails = true;
          })
          .finally(() => (this.loading = false));
      },
  
      // Clear filters
      resetFilters() {
        this.searchQuery = "";
        this.filters.location_id = "";
        this.filters.employeStatus = "2";
        this.filters.report_date = [
          moment().subtract(7, "days").format("MM/DD/YYYY"),
          moment().format("MM/DD/YYYY"),
        ];
        this.fetchData();
      },
  
      // Main fetch call
      fetchData() {
        this.loading = true;
        this.$http
          .post("user/activity-report", {
            search: this.searchQuery,
            page: this.currentPage,
            column: this.sortedColumn, // from mixin
            order: this.order,         // from mixin
            report_start_date: this.filters.report_date?.[0],
            report_end_date: this.filters.report_date?.[1],
            per_page: this.perPage,
            company_id: this.filters.location_id,
            status: this.filters.employeStatus,
            // potentially other filters as needed
          })
          .then((resp) => {
            // Expect the response shape:
            // {
            //   report: [ ... array of activities ... ],
            //   total: 123
            // }
            let report_data = resp.data.report || [];
            this.totalData = resp.data.total || 0;
  
            // Format the data if needed (like date formatting)
            this.tableData = report_data.map((item) => {
              return {
                id: item.id,
                activity_type_label: item.activity_type_label,
                performed_by_name: item.performed_by_name,
                performed_on_name: item.performed_on_name,
                description_text: item.description_text,
                created_at: moment(item.created_at).format("MM-DD-YYYY HH:mm"),
                location: item.location || "-",
              };
            });
          })
          .finally(() => (this.loading = false));
      },
  
      // Excel export, similar logic to your existing code
      exportExcel() {
        this.loading = true;
        this.$http
          .post("user/activity-report", {
            search: this.searchQuery,
            page: this.currentPage,
            column: this.sortedColumn,
            order: this.order,
            report_start_date: this.filters.report_date?.[0],
            report_end_date: this.filters.report_date?.[1],
            per_page: this.perPage,
            company_id: this.filters.location_id,
            status: this.filters.employeStatus,
            isExcelDownload: true,
          })
          .then((resp) => {
            // 'resp.data.download' might be an array of objects
            let report_data = resp.data.download || [];
            this.items = report_data;
  
            // Convert to sheet and prompt download
            const sheet = XLSX.utils.json_to_sheet(this.items);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "data");
            XLSX.writeFile(wb, "UserActivityHistory.xlsx");
          })
          .finally(() => (this.loading = false));
      },
    },
  };
  </script>
  
  <style scoped>
  .no-border-card .card-footer {
    border-top: 0;
  }
  
  /* Example responsive styles for the table (like your existing code) */
  @media only screen and (max-width: 1024px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    .loginReportGrid >>> table.el-table__body td:nth-of-type(1):before {
      content: "Activity";
    }
    .loginReportGrid >>> table.el-table__body td:nth-of-type(2):before {
      content: "Performed By";
    }
    .loginReportGrid >>> table.el-table__body td:nth-of-type(3):before {
      content: "Performed On";
    }
    .loginReportGrid >>> table.el-table__body td:nth-of-type(4):before {
      content: "Description";
    }
    .loginReportGrid >>> table.el-table__body td:nth-of-type(5):before {
      content: "Activity Date";
    }
    .loginReportGrid >>> table.el-table__body td:nth-of-type(6):before {
      content: "Location";
    }
  }
  </style>
  